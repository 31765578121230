import React, { useState, useEffect } from "react"
import BlissStates from "../components/bliss-states"


/* Förstår inte riktigt vad som händer men detta är taget från:
https://stackoverflow.com/questions/61467803/how-to-use-intersectionobserver-with-react-hooks-in-gatsby
och https://coolcssanimation.com/how-to-trigger-a-css-animation-on-scroll/
Syftet är att sätta klassen "is-visible" på .bliss-wrapper för att påbörja
animationen när den blir synlig på skärmen */

const handleObserverEvent = entries => {
  // This successfully logs the entries
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('is-visible')
    }
    else {
      entry.target.classList.remove('is-visible')
    }
  })
}

const BlissContainer = () => {
  const [observer, setObserver] = useState(null)

  useEffect(() => {
    setObserver(new IntersectionObserver(handleObserverEvent, {}))

    return () => {
      if (observer) observer.disconnect()
    }
  }, [])

  const observeElement = ref => {
    if (observer) observer.observe(ref)
  }

  return (
    <div>
        <BlissStates
          observeElement={observeElement}
        />
    </div>
  )
}

export default BlissContainer
