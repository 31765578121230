import React, { Component } from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import BlissContainer from "../components/bliss-container"

export default function BubblesOfBliss({ data, location }) {

  return <Layout location={location}>
    <h1>Bubbles <em>of</em> <span className="highlight yellow inline-block">Bliss</span></h1>
    <p className="heading-intro">Kreativitet ❤︎ Effektivitet = Sant</p>

    <p>Ur en växande frustration över att projekt – både de som jag hade ansvar
    för och de som jag deltog i – ofta drog ut på tiden, att personer i
    projektgruppen hade skilda förväntningar på resultatet och att leveranser
    ofta färdigställdes med en viss känsla av besvikelse började jag försöka
    förstå <em>varför</em> det blev så och <em>hur</em> vi skulle hantera det.</p>

    <p>Det ledde fram till <em>Bubbles of Bliss</em> – <strong>en agil metod som
    lyfter fram känslorna i ljuset</strong> istället för att låtsas som att de
    inte finns. Som helt enkelt drar fördel av att vi alla är människor.</p>

    <p>När vi började använda den här metoden kunde vi konstatera att projekten
    blev lustfyllda från början till slut och att vi konsekvent började leverera
    med en känsla av bra teamwork och effektiva resultat.</p>

    <section className="bob-container">
      <div className="bubbles-of-bliss">
        <p className="heading-intro">Vad är Bubbles of Bliss?</p>
        <h2>Allt handlar om känslor!</h2>
        <p><span class="p-intro">Hemligheten</span> för att uppnå bra resultat
        är helt enkelt att förstå och hantera känslor. Eftersom känslor ger
        upphov till attityder, attityder ger upphov till beteenden, och
        beteenden avgör om vi lyckas med något eller inte. Det gäller såväl hos
        dem som ska använda det vi producerar som hos personerna i teamet som
        ska samarbeta. Bubbles of Bliss tar avstamp i våra känslor för att uppnå
        effektiva samarbeten och resultat.</p>

        <p>Såhär fungerar det:</p>
        <div className="bob-fact columns">
          <div className="col col-1">
            <h3>Bubblor</h3>
            <p>… är något tidsbegränsat som har en början, ett slut och ett
            syfte. Det kan vara ett möte, din arbetsdag, en sprint eller ett
            längre projekt. Något du gör ensam eller i ett team. Oavsett typ,
            måste varje bubbla hanteras på samma sätt.</p>
          </div>
          <div className="col col-2">
            <h3>Bliss</h3>
            <p>… är ett mentalt tillstånd som ger dig utrymme att hantera
            komplexa problem och känslor. När du befinner dig i bliss kan du ta
            in flera perspektiv samtidigt och du kan reagera rationellt snarare
            än instinktivt på känslor som uppstår.</p>
          </div>
        </div>

        <BlissContainer />

        <p><span class="p-intro">Som ledare</span> är en av dina viktigaste
        uppgifter att säkerställa att du själv, personerna i teamet och andra
        som påverkas av det vi gör kan arbeta och samarbeta i ett tillstånd av
        bliss. Om vi inte kan det, utan istället kämpar med till exempel
        osäkerhet, oro, stress eller distraktioner, så kommer vi inte att kunna
        använda våra tillgängliga resurser optimalt, och vi kommer inte kunna
        uppnå hög kvalitet. Men om vi lyckas eliminera de negativa känslorna,
        kan vi använda våra resurser fullt ut, nå maximal kvalitet och ändå bli
        klara på utsatt tid.</p>

        <p>Kom ihåg att detta är precis lika relevant när du arbetar helt ensam
        och är din egen ledare.</p>

        <p>Här följer en detaljerad genomgång om varför bliss är viktigt och hur
        vi kan gå tillväga för att uppnå det i de projekt vi arbetar i.
        </p>

        <p className="heading-intro">Varför är det viktigt?</p>
        <h2>Bliss ger flow</h2>
        <ol class="timeline">

        <li class="item"><span class="p-intro">Innovation</span><br />
        När vi befinner oss i bliss, så kan vi hålla flera pers­pektiv i huvudet
        samtidigt och hitta nya lösningar på komplexa problem. Om vi känner oss
        fokuserade och inspi­rerade, så kommer vi kunna komma med nya idéer och
        inspirera andra. Om vi känner oss säkra och avslappnade, kommer vi inte
        vara rädda för att experimentera och göra misstag. Och det är vad som
        krävs för att hitta nya, banbrytande lösningar.</li>

        <li class="item"><span class="p-intro">Empati</span><br />
        I ett tillstånd av bliss kan vi förstå och hantera både våra egna och
        andra personers känslor och perspektiv. Du kanske inte håller med, men
        du kan fortfarande sätta dig in i den andra personens synsätt. Detsamma
        gäller användarna av dina produkter eller tjänster: om du har
        tillräckligt mentalt utrymme för att agera empatiskt, så kommer du kunna
        förstå användarna och deras behov på djupet.</li>

        <li class="item"><span class="p-intro">Förståelse, omtanke, förtroende</span><br />
        Med innovation och empati kommer vi att kunna nå en djupare nivå av
        förståelse för problemet, för våra teammedlemmar, stakeholders, oss
        själva och våra användare. Ju mer vi arbetar ihop och förstår varandra
        och problemet, desto mer kommer vi att börja bry oss om varandra,
        resultatet och våra användare. Vi kommer att få större förtroende för
        att våra teammedlemmar gör ett bra jobb, och för att våra teammedlemmar
        litar på att vi själva gör ett bra jobb.</li>

        <li class="item"><span class="p-intro">Flow</span><br />
        Om vi lyckas uppnå allt ovanstående, så kommer vi att kunna arbeta i ett
        tillstånd av flow, där vi kan vara maximalt kreativa och maximalt
        effektiva på samma gång.</li>
      </ol>

      </div>
      <div className="bob-image">
        <figure>
          <figcaption>
           <span class="p-intro">Double Rainbow with Tornado</span> är ett
           performance­konstverk av <a href="https://salorantadevylder.com/">Saloranta &
           De Vylder</a> som gav inspiration till konceptet Bubbles of Bliss.
           Som en del av verket fick publiken gå in i en uppblåst luftballong.
           Känslan av att befinna sig inne i den där bubblan – skyddad från
           omvärlden, nyfiken och förvantansfull – påminde mig om känslan av det
           perfekta projektet.
           Foto: <a href="https://vimeo.com/298151347">Lithuanian Dance</a>
          </figcaption>
          <StaticImage
             src="../images/double-rainbow-with-tornado.jpg"
             alt="Double Rainbow with Tornado by Saloranta o de Vylder"
             placeholder="blurred"
             quality={100}
             className="tornado-image"
             layout="constrained"
           />

         </figure>
      </div>
      <div className="bubbles-of-bliss">
        <p className="heading-intro">Hur går det till?</p>
        <h2>1. Definiera bubblan</h2>
        <p><span class="p-intro">Det första</span> vi måste göra är att komma
        överens om parametrarna som styr vår bubbla:</p>
        <ul>
          <li><strong>Tidsramar:</strong> Ska vi vara klara nästa vecka eller om ett år?</li>
          <li><strong>Resurser:</strong> Är vi två personer eller 25? Har vi 20 000 kronor eller
          20 miljoner?</li>
          <li><strong>Förväntat resultat:</strong> Vad förväntar vi oss när det kommer till
          kvalitet, mål och produktegenskaper? Siktar vi på att bli
          marknadsledande eller räcker det att det vi gör funkar?</li>
        </ul>
        <p><span class="p-intro">Alla i teamet</span> (och ledningen!) måste
        förstå att alla parametrar påverkar varandra och att verkligheten sällan
        blir som planerat. Därför behöver vi redan nu komma överens om hur vi
        ska hantera avvikelser från planen. Om vi har en stenhård deadline,
        måste vi vara beredda att skjuta till resurser för att bli klara i tid.
        Om budgeten inte går att utöka, måste vi vara beredda på att resultatet
        kan bli sämre än förväntat. Om resultatet inte är förhandlingsbart,
        finns det stor risk att vi behöver lägga mer tid och pengar än
        planerat.</p>

        <p>Vad gör vi om vi inser att det kommer att ta längre tid än det var
        tänkt? Vad är ett giltigt skäl för att skjuta till mer resurser? Kan vi
        nöja oss med ett mindre ambitiöst resultat?</p>

        <p className="heading-intro">&nbsp;</p>
        <h2>2. Förbered för bliss</h2>
        <ol class="timeline">

          <li class="item"><span class="p-intro">Praktiska förberedelser</span>,
          som att säkerställa att vi har rätt material, att vi har blockerat tid
          i kalendern, att vi har förberett alla verktyg för projektledning och
          så vidare, kommer att reducera risken för negativa känslor, som
          osäkerhet och stress, när projektet fortsätter.
          </li>

          <li class="item"><span class="p-intro">Teamförberedelser</span>, som
          att säkerställa att alla förstår sina egna och teammedlemmarnas roller,
          ansvar och mandat, förebygger känslor av oro och osäkerhet. Alla
          behöver förstå att vi från och med nu är <em>ett team</em> där alla
          kommer att göra sitt bästa utifrån förutsättningarna och alla kommer
          att göra misstag. Vi behöver vara överens om att vi ska lära oss av
          våra misstag och hjälpas åt att lösa problem.
          </li>

          <li class="item"><span class="p-intro">Känslomässiga förberedelser</span>.
          Kanske är det redan något som inte känns bra. Ta upp det! Kanske har
          du redan förstått att vi inte kommer att hinna klart i tid. Ta upp det!
          Ju längre vi väntar med att synliggöra ett problem, desto större blir
          det, och vi kommer att behöva lägga mer tid på att göra saker ogjorda
          som vi inte ens hade påbörjat om vi kände till problemet från början.
          </li>

          <li class="item"><span class="p-intro">Förutse framtiden</span>.
          Försök att föreställa dig projektets olika skeden och vad som skulle
          kunna bli ett problem eller orsaka de negativa känslorna av
          distraktion, oro, stress och osäkerhet som vi vill eliminera. Prata om
          det på en gång: hur ska vi hantera sådana problem när de inträffar?
          Kan vi göra något för att förhindra att det händer?
          </li>
        </ol>

        <p className="heading-intro">&nbsp;</p>
        <h2>3. Håll bliss vid liv</h2>

        <p>När vi förberett oss är det dags att börja jobba. För att stanna kvar
        i bliss, behöver vi designa processen så att vi kan identifiera och
        hantera våra känslor längs vägen och så att alla känner sig trygga med
        att det kommer att finnas regelbundna tillfällen då jag har möjlighet
        att ta upp och hantera problem eller negativa (och positiva!) känslor
        tillsammans med resten av teamet.</p>

        <ol class="timeline">

          <li class="item"><span class="p-intro">Dagliga standups</span> Varje
          arbetsdag börjar med att teammedlemmarna i tur och ordning delar med
          sig av vad de gjorde igår, vad de ska göra idag och om de behöver
          hjälp med något. Innan turen går vidare till nästa person delar du med
          dig av en kort reflektion.
          </li>

          <li class="item"><span class="p-intro">Status-checkin</span> Varje
          vecka stämmer vi av projektets status och säkerställer att vi arbetar
          enligt planen och håller oss inom bubblans ramar. Eftersom vi redan
          har förberett oss för att saker kan gå fel, så är vi beredda på att
          hantera en situation då vi behöver lägga till mer resurser, sänka
          ambitionsnivån eller skjuta på deadline.
          </li>

          <li class="item"><span class="p-intro">Produktionsmöten</span> Vi har
          regelbundna produktionsmöten där alla som har arbetat med någon typ av
          produktion bjuds in för att presentera och få feedback på sitt arbete
          och bestämma hur vi ska gå vidare. Det här är ett tillfälle att:
          <ul>
            <li>kvalitetssäkra vårt arbete</li>
            <li>förstärka känslan av förståelse, omtanke och förtroende</li>
            <li>visualisera våra framsteg</li>
          </ul>
          </li>

          <li class="item"><span class="p-intro">Retrospektiv</span> Mer sällan,
          ungefär varannan månad, har vi retrospektiv där vi samlar hela teamet
          och projektets intressenter för att prata om och utvärdera vårt
          samarbete. En väldigt enkel och mycket värdefull övning där vi pratar
          om:
          <ul>
            <li>vad som har gått lika bra som förväntat</li>
            <li>vad som inte gick så bra som vi förväntade oss</li>
            <li>vad vi behöver börja göra annorlunda framöver</li>
            <li>vad som har varit riktigt bra: Vem har gjort något
            fantastiskt? Vilka exceptionella resultat har vi redan uppnått?</li>
          </ul>
          </li>

        </ol>

        <p className="heading-intro">&nbsp;</p>
        <h2>4. Stäng bubblan</h2>

        <p>Till sist, när projektet närmar sig sitt slut, gör vi oss själva en
        stor tjänst om vi ser till att stänga bubblan ordentligt.</p>

        <ol class="timeline">

          <li class="item"><span class="p-intro">Bränn backlogen</span> Låt inte
          uppgifter, som inte kommer att slutföras nu, vara kvar i
          projekthanteringssystemet. Vi kommer inte att vilja starta upp nästa
          projektperiod med att försöka komma ihåg vad vi menade och varför vi
          tyckte att något var viktigt. Släpp taget och lita på vårt framtida
          omdöme! Vi kommer förmodligen att inse att det är något annat som är
          viktigt nästa gång vi arbetar vidare på projektet.
          </li>

          <li class="item"><span class="p-intro">Fira!</span> Ett litet firande
          kan vara att gå igenom och summera vad vi har åstadkommit under den här
          bubblan. Men om möjligt: slå på stort och ordna en fantastisk fest
          där vi kan fira vår briljans tillsammans!
          </li>

        </ol>

      </div>
    </section>

  </Layout>
}
