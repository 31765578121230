import React, { useRef, useEffect } from "react"

const BlissStates = ({ observeElement }) => {
  const ref = useRef()

  useEffect(() => {
    if (ref.current) observeElement(ref.current)
  }, [observeElement])

  return (
    <div
      className={'bliss-wrapper'}
      ref={ref}
    >
      <p></p>
      <div className="animation-wrapper">
        <div className="animation-row">
          <div className="parameters parameters-left">
            <p>Utmattad</p>
            <p>Besatt</p>
            <p>Likgiltig</p>
            <p>Risktagande</p>
          </div>
          <div className="parameters parameters-center">
            <p><span>Fokuserad</span></p>
            <p><span>Inspirerad</span></p>
            <p><span>Avslappnad</span></p>
            <p><span>Trygg</span></p>
          </div>
          <div className="parameters parameters-right">
            <p>Distraherad</p>
            <p>Orolig</p>
            <p>Stressad</p>
            <p>Osäker</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlissStates
